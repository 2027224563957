import SubscriptionCard from './SubscriptionCard'
import { memo, useEffect, useState } from 'react'
import fetchData from '../../../hook/fetchData'
import PaymentSystemPopup from './PaymentSystemPopup'
import CancelSubscriptionPopup from './CancelSubscriptionPopup'
import { getEnv } from "@urecruits/api"
import { useHasPermission, AuthGuard, SmallLoaderComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'
import InvoicesCard from './InvoicesCard'
const noneSubscriptionImg = require('../../../image/none_subscription.svg')

// const packages = ['Recruitment', 'Assessment', 'Full cycle']
const SubscriptionTab = () => {
  const { checkUserPermission } = useHasPermission()
  //data for PaymentSystemPopup(for update subscription plan)
  const [subscriptionData, setSubscriptionData] = useState({
    popupVisible: false,
    paymentMethodId: '',
    priceId: '',
    planId: '',
    duration: 'y',
    subscriptionId: '',
    packageId: '',
  })

  const [cancelSubscriptionData, setCancelSubscriptionData] = useState({
    subId: '',
    popupVisible: false,
  })

  //tabs display action
  const [period, setPeriod] = useState('monthly')
  const [currentPeriod, setCurrentPeriod] = useState('monthly')
  const [activePackage, setActivePackage] = useState(null)
  const [tabDisplay, setTabDisplay] = useState('current')

  const [isAllSubscriptionLoading, setIsAllSubscriptionLoading] = useState<boolean>(false)
  const [isCurrentSubscriptionLoading, setIsCurrentSubscriptionLoading] = useState<boolean>(false)
  const [subscriptions, setSubscriptions] = useState(null)
  const [allSubscriptions, setAllSubscriptions] = useState(null)
  const [currentSubscription, setCurrentSubscription] = useState(null)
  const [currentPlan, setCurrentPlan] = useState(null)
  const [refetch, setRefetch] = useState('')
  const { API_RECRUITMENT } = getEnv()

  //set all plans data and current subscription
  useEffect(() => {
    setIsAllSubscriptionLoading(true);
    setIsCurrentSubscriptionLoading(true);
    checkUserPermission('subscription', 'view') && fetchData(`${API_RECRUITMENT}/api/subscription/get-plans`, setAllSubscriptions).then(data=>{
      setIsAllSubscriptionLoading(false);
    });
    checkUserPermission('subscription', 'view') && fetchData(`${API_RECRUITMENT}/api/subscription/get-company-main-subscription`, setCurrentSubscription).then(data=>{
      setIsCurrentSubscriptionLoading(false);
    })
  }, [refetch])

  useEffect(() => {
    // console.log({currentSubscription})
    if (currentSubscription && currentSubscription.subId != "noSubscription") {
      if (currentSubscription.prices.hasOwnProperty('year') && currentPeriod != 'yearly') {
        setCurrentPeriod('yearly');
      } else if (currentSubscription.prices.hasOwnProperty('month') && currentPeriod != 'monthly') {
        setCurrentPeriod('monthly');
      }
    }
  }, [currentSubscription]);

  useEffect(() => {
    if (subscriptions && subscriptions.length) {
      setActivePackage({ packageId: subscriptions[0].id, packageName: subscriptions[0].packageName })
    }
  }, [subscriptions])

  //convert Date
  const convertData = (value) => {
    return new Date(value * 1000).toLocaleDateString('en-US')
  }

  //calc subscription day left
  const subscriptionDayLeft = (currentDay, periodEndDay) => {
    const date1 = new Date(currentDay)
    const date2 = new Date(periodEndDay * 1000)
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24
    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime()

    return Math.round(diffInTime / oneDay)
  }

  //Find current subscription plan
  const findCurrentSubscriptionPlan = () => {
    if (subscriptions && currentSubscription) {
      let atsFindResult: any = null;
      subscriptions.forEach(sub => {
        let data = sub.tiers?.find(item => {
          return item.id == currentSubscription.productId;
        })
        if (data) {
          atsFindResult = data;
          atsFindResult.isCurrentSubCard = true; // exist in current(latest) subscription list
          atsFindResult.isCurrentSubPrice = data.prices[currentPeriod] && data.prices[currentPeriod].id === currentSubscription.priceId ? true : false; //does not match its price with current subscription price list
          atsFindResult.isCurrentSubPriceDuration = currentPeriod; //does not match its price with current subscription price list
        } else {
          atsFindResult = currentSubscription;
        }
      })

      if(!atsFindResult && currentSubscription){
        atsFindResult = currentSubscription;
      }
      return atsFindResult
    }
  }

  //set subId
  useEffect(() => {
    if (currentSubscription) {
      (currentSubscription.subId === "noSubscription") && (setTabDisplay('subscriptionNone'));
      (currentSubscription.status !== 'active' && currentSubscription.status !== 'trialing') && (setTabDisplay('subscriptionNone'));
      setSubscriptionData({ ...subscriptionData, subscriptionId: currentSubscription.subId, planId: currentSubscription.planId, packageId: currentSubscription.packageId })
    }
  }, [currentSubscription])

  useEffect(() => {
    if(subscriptions && currentSubscription){
      const plan = findCurrentSubscriptionPlan();
      // console.log({plan})
      setCurrentPlan(plan)
    }
  }, [currentPlan, subscriptions, currentSubscription])

  useEffect(() => {
    let allowed;
    if(allSubscriptions && currentSubscription && currentSubscription.subId != 'noSubscription' && currentSubscription.hasOwnProperty('packageType')){
      allowed = allSubscriptions.map((sub)=>{
        const allowedSubscriptionPackages = currentSubscription.hasOwnProperty('acceptedPackageType') ? currentSubscription.acceptedPackageType : [];
        sub.tiers = sub.tiers.filter(tier=>{
          return (currentSubscription.packageType===tier.packageType || (allowedSubscriptionPackages.length && allowedSubscriptionPackages.includes(tier.packageType)) );
        });
        return sub;
      }).filter(sub=>{
        return sub.tiers.length
      });
      setSubscriptions(allowed);
    }else if (allSubscriptions && currentSubscription){
      allowed = allSubscriptions.map((sub)=>{
        sub.tiers = sub.tiers.filter(tier=>{
          return !!tier.packageType //this condition is to show all packages having packageType
        });
        return sub;
      }).filter(sub=>{
        return sub.tiers.length
      });
      setSubscriptions(allowed);
    }
  }, [allSubscriptions, currentSubscription]);
  return (
    <AuthGuard module='subscription' permission={['view', 'add', 'edit', 'delete']}>
      <div className="profile__right__inner">
            <div className="subscription">
        {isCurrentSubscriptionLoading || isAllSubscriptionLoading ? <div className="loader">
          {/* {true ? <div className="loader"> */}
            <SmallLoaderComponent />
        </div> : <>
          <div className="subscription__head">
            <div className="subscription__head__title">
              Subscriptions
            </div>
            {currentPlan && currentSubscription && currentSubscription.subId != "noSubscription" && <div className='subscription__head__switch'>
              <div className={`subscription__head__button ${tabDisplay === 'current' ? 'active' : ''}`} onClick={() => setTabDisplay('current')}>Current subscription</div>
              <div className={`subscription__head__button ${tabDisplay === 'packages' ? 'active' : ''}`} onClick={() => setTabDisplay('packages')}>Packages</div>
              <div className={`subscription__head__button ${tabDisplay === 'invoices' ? 'active' : ''}`} onClick={() => setTabDisplay('invoices')}>Invoices</div>
            </div>}
          </div>
          {
            tabDisplay === 'current' && (
              <>
                <div className="subscription__current">
                  <div className="subscription__current__head">
                    <div className="subscription__current__left">
                      {
                        currentSubscription && (
                          <p className="subscription__current__title">
                            Current subscriptions - <span>{`${currentSubscription.packageName}`} ({currentSubscription.planName})</span>
                          </p>
                        )
                      }
                      {
                        currentSubscription && currentSubscription.subId === 'noSubscription' && (
                          <p className="subscription__current__description">
                            You now have a free trial activated. You can upgrade to a paid subscription at any time.
                          </p>
                        )
                      }
                      {
                        currentSubscription && currentSubscription.subId !== 'noSubscription' && (
                          <p className="subscription__current__description">
                            You have an active subscription. You can change it at any time.
                          </p>
                        )
                      }
                    </div>
                    <div className="subscription__current__right">
                      <ul className="subscription__current__list">
                        {
                          checkUserPermission('subscription', 'view') && currentSubscription && currentSubscription.subId === 'noSubscription' && (
                            <>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Package:
                                </p>
                                <p className="subscription__current__item--value">
                                  {`${currentSubscription.packageName}`}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Tier:
                                </p>
                                <p className="subscription__current__item--value">
                                  {currentSubscription.planName}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Trial active to:
                                </p>
                                <p className="subscription__current__item--value">
                                  {convertData(currentSubscription.currentPeriodEnd)}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Days untial due:
                                </p>
                                <p className="subscription__current__item--value">
                                  {subscriptionDayLeft(Date.now(), currentSubscription.currentPeriodEnd)}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Status:
                                </p>
                                <p className="subscription__current__item--value">
                                  {currentSubscription.status}
                                </p>
                              </li>
                            </>
                          )
                        }
                        {
                          checkUserPermission('subscription', 'view') && currentSubscription && currentSubscription.subId !== 'noSubscription' && (
                            <>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Package:
                                </p>
                                <p className="subscription__current__item--value">
                                  {`${currentSubscription.packageName}`}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Tier:
                                </p>
                                <p className="subscription__current__item--value">
                                  {currentSubscription.planName}
                                </p>
                              </li>
                              <li className="subscription__current__item">
                                <p className="subscription__current__item--key">
                                  Status:
                                </p>
                                <p className="subscription__current__item--value">
                                  {currentSubscription.status}
                                </p>
                              </li>
                            </>
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="subscription__current__body">
                    {
                      checkUserPermission('subscription', 'view') && subscriptions && currentSubscription && currentPlan && (
                        <SubscriptionCard
                          setTabDisplay={setTabDisplay}
                          tabDisplay={tabDisplay}
                          cardData={currentPlan}
                          period={currentPeriod}
                          setSubscriptionData={setSubscriptionData}
                          subscriptionData={subscriptionData}
                          currentSubscription={currentSubscription}
                          cancelSubscriptionData={cancelSubscriptionData}
                          setCancelSubscriptionData={setCancelSubscriptionData}
                          packageData={{ packageId: currentSubscription.packageId, packageName: currentSubscription.packageName }}
                        />
                      )
                    }
                  </div>
                </div>

              </>
            )
          }
          {
            tabDisplay == 'packages' && checkUserPermission('subscription', 'view') && (
              <div className="packages">
                <div className="packages__head">
                  <div className='packages__head__left'>
                    <div className="packages__menu">
                      {
                        subscriptions && subscriptions.map((subscription, i) => {
                          return <div
                            key={subscription.packageName}
                            className={`packages__menu__item ${activePackage && activePackage.packageName == subscription.packageName ? 'active' : ''}`}
                            onClick={() => { setActivePackage({ packageId: subscription.id, packageName: subscription.packageName }) }}
                          >
                            {subscription.packageName}
                          </div>
                        })
                      }
                    </div>
                  </div>
                  <div className="packages__head__right">
                    <form className="packages__period">
                      <div className="packages__period__item">
                        <input
                          type="radio"
                          name="period"
                          id="monthly"
                          className="packages__period__checkbox"
                          value="monthly"
                          onChange={() => setPeriod('monthly')}
                          checked={period === 'monthly'}
                        />
                        <label htmlFor="monthly" className="packages__period__label">Monthly</label>
                      </div>
                      <div className="packages__period__item">
                        <input
                          type="radio"
                          className="packages__period__checkbox"
                          name="period"
                          id="yearly"
                          value="yearly"
                          onChange={() => setPeriod('yearly')}
                          checked={period === 'yearly'}
                        />
                        <label htmlFor="yearly" className="packages__period__label">Yearly</label>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="packages__body">
                  {subscriptions && subscriptions.filter(sub => activePackage && sub.packageName === activePackage.packageName).map((subscription) => {
                    return subscription.tiers?.map((item) => {
                      return item.prices.hasOwnProperty(period === 'monthly' ? 'month' : 'year') ?
                        <SubscriptionCard
                          setTabDisplay={setTabDisplay}
                          tabDisplay={tabDisplay}
                          key={item.id}
                          cardData={item}
                          period={period}
                          setSubscriptionData={setSubscriptionData}
                          subscriptionData={subscriptionData}
                          currentSubscription={currentSubscription}
                          cancelSubscriptionData={cancelSubscriptionData}
                          setCancelSubscriptionData={setCancelSubscriptionData}
                          packageData={{ packageId: subscription.packageId, packageName: subscription.packageName }}
                        /> : <>No {period} plan in {activePackage.packageName} package</>
                    }
                    );
                  })}
                </div>

              </div>
            )
          }
          {
            tabDisplay === 'subscriptionNone' && (
              <>
                <div className="subscription__empty">
                  <div className="subscription__empty__head">
                    <p className="subscription__empty__title">
                      Current subscriptions - <span>None</span>
                    </p>
                    <p className="subscription__empty__description">
                      You have no active subscription. You can buy new at any time.
                    </p>
                  </div>
                  <AuthGuard module='subscription' permission='add'>
                    <AuthGuard module='payment-integration' permission='view'>
                      <div className="subscription__empty__body">
                        <img src={noneSubscriptionImg} alt="subscription__empty__image" />
                        <div className="subscription__empty__button button--filled" onClick={() => setTabDisplay("packages")}>Choose your plan</div>
                      </div>
                    </AuthGuard>
                  </AuthGuard>
                </div>
              </>
            )
          }
          {
            tabDisplay === 'invoices' && <div className='invoices'>
              <InvoicesCard />
            </div>
          }
        </>}
        </div>
      </div>
      {
        subscriptionData.popupVisible && checkUserPermission('subscription', ['add', 'edit']) && (
          <PaymentSystemPopup
            subscriptionData={subscriptionData}
            setSubscriptionData={setSubscriptionData}
            setRefetchSubscriptionData={setRefetch}
            setTabDisplay={setTabDisplay}
            setPeriod={setPeriod}
            setCurrentSubscription={setCurrentSubscription}
          />
        )
      }
      {
        cancelSubscriptionData.popupVisible && checkUserPermission('subscription', 'delete') && (
          <CancelSubscriptionPopup
            setRefetch={setRefetch}
            title="Cancel subscription"
            description="Do you really want to cancel the subscription?"
            setCancelSubscriptionData={setCancelSubscriptionData}
            setSubscriptionData={setSubscriptionData}
            cancelSubscriptionData={cancelSubscriptionData}
          />
        )
      }
    </AuthGuard>
  )
}

export default memo(SubscriptionTab)