import axios from 'axios'
import { memo, useState } from 'react'
import { getEnv } from "@urecruits/api"
import { DashboardListArrowSVG } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'
const { API_RECRUITMENT } = getEnv()

const defaultAvatar = require('../../../../image/icon/avatar.svg')
const token: string = localStorage.getItem('token')

const addCompanyRole=async(roleName)=>{
  try{

    return await axios.post(`${API_RECRUITMENT}/api/company/role/create`, { roleName }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }catch(error){
    console.log("Error adding role to company:",error)
  }
}
const RolesTableRow = ({ item, setActiveRole, setActiveTab, activeRole, isDefault, setRefetch }) => {
  const [displayInformation, setDisplayInformation] = useState(false)
  const [show,setShow] = useState(false)
  return (
    <div
      className={`roles-table__row ${displayInformation ? 'active' : ''}`}>
      <div
        className="roles-table__role"
        onClick={() => setDisplayInformation(!displayInformation)}
      >
        <p
          className="roles-table__role__name"
          onClick={() => {
            setActiveRole({ ...activeRole, name: item.role, authId: item.id })
            setActiveTab('members')
          }}
        >
          {item.role}
        </p>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="roles-table__role__name--arrow"
        >
          <g clipPath="url(#clip0_6057_5825)">
            <path d="M1 3L5 7L9 3" stroke="#999EA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_6057_5825">
              <rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="roles-table__users">
        <p className="roles-table__users__headline">
          Users
        </p>
        <div className="roles-table__users__inner">
          <div className="roles-table__users__left">
            <p className="roles-table__users__count">{item.usersCount} user{item.usersCount > 1 || item.usersCount === 0 ? 's' : ''}</p>
          </div>
          <div className="roles-table__users__right">
            <ul className="roles-table__users__list">
              {
                item.usersInfo && item.usersInfo.map((item, index) => {
                  if (index + 1 <= 3) {
                    return (
                      <li className="roles-table__users__item" key={index}>
                        {
                          item.avatar?.length > 0 ?
                            <img src={item.avatar} alt="user name" className="roles-table__users__avatar" />
                            :
                            <img src={defaultAvatar} alt="user name" className="roles-table__users__avatar" />
                        }
                      </li>
                    )
                  }
                })
              }
            </ul>
            {
              item.usersCount > 3 && (
                <div className="roles-table__users__all">
                  <span className="roles-table__users__all--value">+{item.usersCount - 3}</span>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="roles-table__description">
        <p className="roles-table__description__headline">
          # Description
        </p>
        <div className={`roles-table__description__inner ${show?'show':''}`}>
          <p className="roles-table__description__text">
            {item.description}
          </p>
          <span onClick={()=>setShow(!show)}>
          <DashboardListArrowSVG />
          </span>
        </div>
      </div>
      <div className="roles-table__permission">
        {isDefault ?
          <button
            className="roles-table__permission__button"
            onClick={() => {
              addCompanyRole(item.role).then(data => setRefetch(prev=>!prev))
            }}
          >
            Add Role
          </button>
          :
          <p
            className="roles-table__permission__text"
            onClick={() => {
              setActiveRole({ ...activeRole, name: item.role, authId: item.id })
              setActiveTab('permissions')
            }}
          >
            see permissions
          </p>
        }
      </div>
    </div>
  )
}

export default memo(RolesTableRow)