import DatePicker from 'react-datepicker'
import { memo, useEffect, useState } from 'react'
import SmallLoader from '../../SmallLoader'
import deleteData from '../../../hook/deleteData'
import axios from 'axios'
import { getEnv } from "@urecruits/api"
import { Box, Chip } from "@mui/material"

const paperClipIc = require('../../../image/icon/paper_clip_ic.svg')
const downloadIc = require('../../../image/icon/download_ic.svg')
const removeIc = require('../../../image/icon/delete_ic.svg')

const AwardsDetailsTabItem =
  ({
    editMode,
    currentItem,
    setDataDetails,
    dataDetails,
    setValidateStatus,
    validateStatus,
    displayButton,
    addedMoreEducationButtonRef,
    submitButtonRef,
    index,
    setRefetch,
    setDisplayButton,
    setEditMode,
    setData
  }) => {

    const token: string = localStorage.getItem('token')
    const [awardNameError, setAwardNameError] = useState(false)
    const [companyNameError, setCompanyNameError] = useState(false)
    const [awardDateError, setAwardDateError] = useState(false)
    const [awardFileError, setAwardFileError] = useState(false)

    const [urlLoader, setUrlLoader] = useState(false)
    const [url, setUrl] = useState('')

    const [fileTypeError, setFileTypeError] = useState(false)
    const [fileSizeError, setFileSizeError] = useState(false)
    const [loader, setLoader] = useState(false)

    const fieldsValidate = () => {
      let error = 0
      dataDetails.map((object) => {
        !object.name || object.name.length <= 0 ? setAwardNameError(true) : setAwardNameError(false)
        !object.companyName || object.companyName.length <= 0 ? setCompanyNameError(true) : setCompanyNameError(false)
        !object.date ? setAwardDateError(true) : setAwardDateError(false)

        if (!object.companyName || !object.name || object.name.length <= 0 || object.companyName.length <= 0 || !object.date) {
          error++
        }
      })

      if (error === 0) {
        setValidateStatus(true)
      }
    }

    useEffect(() => {
      if (!validateStatus && editMode) {
        //added more click validate
        addedMoreEducationButtonRef.current.onclick = function () {
          fieldsValidate()
        }

        //on submit click validate
        if (displayButton) {
          submitButtonRef.current.onclick = function () {
            fieldsValidate()
          }
        }
      }
    }, [validateStatus, dataDetails, currentItem, editMode])

    const onRemoveCertificateHandler = () => {
      if (editMode) {
        setDataDetails([...dataDetails].map(object => {
          if (object.id === currentItem.id) {
            return {
              ...object,
              fileKey: '',
              fileName: '',
            }
          } else return object
        }))
        setDisplayButton(true)
        setEditMode(true)
      }
    }

    const onDownloadHandler = async () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      const formData = {
        'key': currentItem.fileKey,
      }
      setUrlLoader(true)
      const { data } = await axios.post(`${API_RECRUITMENT}/api/file/get-private`, formData, config)
      setUrl(data)
      setUrlLoader(false)
    }
    const {API_RECRUITMENT}=getEnv()
    const onRemoveItemHandler = async (e) => {
      e.preventDefault
      if (editMode) {
        if (currentItem?.isResumeParsed) {
          setData(oldData => {
            let awards = [];
            awards = dataDetails?.filter(i => i.id !== currentItem.id)
            oldData.awards = awards;
            return { ...oldData }
          });
        } else {
          const res = await deleteData(`${API_RECRUITMENT}/api/candidate/award`, currentItem);
          if (res.status == 200) {
            setData(oldData => {
              const _oldData = JSON.parse(JSON.stringify(oldData));
              let awards = dataDetails;
              awards = awards.filter(i => i.id !== currentItem.id);
              return { ..._oldData, awards }
            })
          }
        }
      }
    }

    const fileValidate = (value) => {
      return value.size / 1024 < 5000 && (value.type === 'application/pdf' || value.type === 'image/jpeg' || value.type === 'image/png')
    }

    const handleNewFileUpload = async (e) => {
      //validate
      e.target.files[0].size / 1024 > 5000 ? setFileSizeError(true) : setFileSizeError(false)
      e.target.files[0].type === 'application/pdf' || e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png'
        ? setFileTypeError(false)
        : setFileTypeError(true)

      if (fileValidate(e.target.files[0])) {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('upload', file)

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
        setLoader(true)
        const { data } = await axios.post(`${API_RECRUITMENT}/api/file/private`, formData, config)
        setDataDetails([...dataDetails].map(object => {
          if (object.id === currentItem.id) {
            return {
              ...object,
              fileKey: data,
              fileName: file ? file.name : '',
            }
          } else return object
        }))
        setLoader(false)
        setDisplayButton(true)
        setEditMode(true)
      }
    }

    return (
      <>
        <div className="profile__form__part">
          <div className={`flex`}>
            <Box>
              {currentItem?.isResumeParsed && <Chip variant='outlined' color='error' label="Unsaved" />}
            </Box>
            <div className={`profile__form__delete ${!editMode ? 'hide' : ''}`}>
              <div className="profile__form__delete--inner" onClick={(e) => onRemoveItemHandler(e)}>
                <span className="profile__form__delete--text">Delete</span>
                <img src={removeIc} alt="delete icon" className="profile__form__delete--icon" />
              </div>
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item long ${awardNameError ? 'error' : ''}`}>
              <label className="profile__form__label">Award Name<span> *</span></label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter award name' : 'Not indicated'}`}
                readOnly={!editMode}
                value={currentItem.name ? currentItem.name : ''}
                onChange={(e) => {
                  setDataDetails([...dataDetails].map(object => {
                    if (object.id === currentItem.id) {
                      return {
                        ...object,
                        name: e.target.value,
                      }
                    } else return object
                  }))
                  e.target.value.length <= 0 ? setAwardNameError(true) : setAwardNameError(false)
                }}
              />
              <p className="error-message">This is required field</p>
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item w67 ${companyNameError ? 'error' : ''}`}>
              <label className="profile__form__label">Organization Name<span> *</span></label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter organization name' : 'Not indicated'}`}
                readOnly={!editMode}
                value={currentItem.companyName ? currentItem.companyName : ''}
                onChange={(e) => {
                  setDataDetails([...dataDetails].map(object => {
                    if (object.id === currentItem.id) {
                      return {
                        ...object,
                        companyName: e.target.value,
                      }
                    } else return object
                  }))
                  e.target.value.length <= 0 ? setCompanyNameError(true) : setCompanyNameError(false)
                }}
              />
              <p className="error-message">This is required field</p>
            </div>
            <div className={`profile__form__item short ${awardDateError ? 'error' : ''}`}>
              <label className="profile__form__label">Award Date<span> *</span></label>
              <div className="profile__form__date">
                <DatePicker
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={70}
                  selected={currentItem.date ? new Date(currentItem.date) : null}
                  onChange={(date: any) => {
                    setDisplayButton(true)
                    setDataDetails([...dataDetails].map(object => {
                      if (object.id === currentItem.id) {
                        return {
                          ...object,
                          date: date,
                        }
                      } else return object
                    }))
                    !date ? setAwardDateError(true) : setAwardDateError(false)
                  }}
                  dateFormat="MMMM d, yyyy"
                  maxDate={new Date()}
                  readOnly={!editMode}
                  placeholderText="Enter award date"
                />
              </div>
              <p className="error-message">This is required field</p>
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item long`}>
              <label className="profile__form__label">Notes</label>
              <textarea
                className="profile__form__textarea"
                placeholder={`${editMode ? 'Write notes' : 'Not indicated'}`}
                readOnly={!editMode}
                value={currentItem.notes ? currentItem.notes : ''}
                onChange={(e) => {
                  setDataDetails([...dataDetails].map(object => {
                    if (object.id === currentItem.id) {
                      return {
                        ...object,
                        notes: e.target.value,
                      }
                    } else return object
                  }))
                }}
              />
              <p className="error-message">This is required field</p>
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item long">
              {
                currentItem.fileKey ?
                  <div className={`profile__upload ${editMode ? '' : 'readonly'}`}>
                    <p className="profile__upload__type">
                      Award File
                    </p>
                    <div className="profile__upload__body">
                      <div className="profile__upload__left">
                        <div className="profile__upload__info">
                          <div className="profile__upload__button">
                            <img src={paperClipIc} alt="upload icon" className="profile__upload__button--icon"/>
                            <p className="profile__upload__button--name">
                              {currentItem.fileName}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="profile__upload__right">
                        {!url && (
                          <img src={downloadIc} alt="download icon" className="profile__upload__download"
                               onClick={onDownloadHandler}/>
                        )}
                        {urlLoader && <p>Loading...</p>}
                        {url && <a href={url}>Download</a>}
                        <img
                          src={removeIc}
                          alt="remove icon"
                          className="profile__upload__remove"
                          onClick={onRemoveCertificateHandler}
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="profile__file last">
                    {loader && (<SmallLoader/>)}
                    <input
                      type="file"
                      name="uploadCv"
                      id="uploadCv"
                      disabled={!editMode}
                      accept="application/pdf, image/*"
                      className="profile__file__input"
                      onChange={handleNewFileUpload}
                    />
                    <label
                      htmlFor="uploadCv"
                      className={`profile__file__button  ${editMode ? 'button--empty' : 'button--empty-disable'}`}
                    >
                      Upload file
                    </label>
                    <p className="profile__file__info">
                    <span className="profile__file__info--text">Max size:<span
                      className="profile__file__info--value"> 1Mb</span>
                    </span>
                      <span className="profile__file__info--text">File type:<span
                        className="profile__file__info--value"> .images, .pdf</span></span>
                    </p>
                    <p className="error-message">
                      {fileSizeError ? 'File size must be less than 5 MB.' : ''} {fileTypeError ? ' Incorrect file type.' : ''}
                    </p>
                  </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

export default memo(AwardsDetailsTabItem)