import '../styles/main.scss'
import { useEffect, useState } from 'react'
import RecruiterProfileScreen from '../screen/RecruiterProfileScreen'
import CandidateProfileScreen from '../screen/CandidateProfileScreen'
import CompanyProfileScreen from '../screen/CompanyProfileScreen'
import axios from 'axios'
import { Provider } from 'react-redux'
import { store } from "./../store"
import { SubscriptionProvider, useHasPermission } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'

export default function Root(props) {
  const { checkUserPermission } = useHasPermission()
  const [usState, setUsState] = useState([])
  const [countryList, setCountryList] = useState([])
  const [nationalityList, setNationalityList] = useState([])
  const [languagesList, setLanguagesList] = useState([])
  const [positionIndustryList, setPositionIndustryList] = useState([])
  const [visaTypes, setVisaTypes] = useState([{ label: '', value: '' }])

  useEffect(() => {
    (async () => {
      try {
        const usStateData = await axios.get('https://urecruits-fonts.s3.amazonaws.com/states_titlecase.json')
        const countryListData = await axios.get('https://urecruits-fonts.s3.amazonaws.com/country_list.json')
        const nationalityListData = await axios.get('https://urecruits-fonts.s3.amazonaws.com/people_nationality_list.json')
        const languagesListData = await axios.get('https://urecruits-fonts.s3.amazonaws.com/languages_list.json')
        const positionIndustryListData = await axios.get('https://cms-dev.urecruits.com/industries-and-positions')
        const visaData = await axios.get('https://cms-dev.urecruits.com/visa-types')

        setVisaTypes(visaData.data.Types)
        setUsState(usStateData.data)
        setCountryList(countryListData.data)
        setNationalityList(nationalityListData.data)
        setLanguagesList(languagesListData.data)
        setPositionIndustryList(positionIndustryListData.data)

      } catch (e) {
        console.error('Fetch error', e)
      }
    })()
  }, [])

  return (
    <SubscriptionProvider>
      <Provider store={store}>
        {
          checkUserPermission(['company-owner','company-admin','recruiter'])
          ?
          <SubscriptionProvider>
          {
                checkUserPermission('company-owner')
                  ? <CompanyProfileScreen countryList={countryList} usState={usState} />
                  :
                  checkUserPermission(['recruiter', 'company-admin']) &&
                    <RecruiterProfileScreen
                      usState={usState}
                      countryList={countryList}
                      nationalityList={nationalityList}
                      languagesList={languagesList}
                      positionIndustryList={positionIndustryList}
                    />
          }
            </SubscriptionProvider>
              :
              checkUserPermission('candidate') &&
              <CandidateProfileScreen
                usState={usState}
                countryList={countryList}
                nationalityList={nationalityList}
                languagesList={languagesList}
                positionIndustryList={positionIndustryList}
                visaTypes={visaTypes}
              />
        }
      </Provider>
    </SubscriptionProvider>
  );
}
